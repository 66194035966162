<template>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color" class="icon-profile">
    <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
        <g>
            <g>
                <circle class="st0" cx="125" cy="125" r="124.5"/>
            </g>
            <g>
                <g>
                    <g>
                        <path class="st1" d="M125,71.3c-14.7,0-26.6,11.9-26.6,26.6s11.9,26.6,26.6,26.6s26.6-11.9,26.6-26.6S139.7,71.3,125,71.3z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path class="st1" d="M161,145.7c-7.9-8-18.4-12.5-29.6-12.5h-12.9c-11.2,0-21.7,4.4-29.6,12.5c-7.9,8-12.2,18.6-12.2,29.8       c0,1.8,1.4,3.2,3.2,3.2h90c1.8,0,3.2-1.4,3.2-3.2C173.2,164.3,168.9,153.8,161,145.7z"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>
<script>
export default {
    name: 'Profile',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
	.st0{fill:#E3F4FE;}
	.st1{fill:#20A5CE;}
</style>